import LoginPage from '../src/login/LoginPage';
import nookies from 'nookies';

export async function getServerSideProps(context) {
    try {
        const cookies = nookies.get(context);

        const user = JSON.parse(cookies?.user || '{}');
        if (user && user?.authToken) {
            return {
                redirect: {
                    destination: '/dashboard',
                    permanent: false,
                },
            };
        }

        return { props: {} };
    } catch {
        return { props: { userData: {} } };
    }
}

export default LoginPage;
